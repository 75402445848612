#menu{
    z-index: 5;
    position: fixed;
    width: 100%;
    height: 112px;
    padding: 0;
    margin: 0;
    text-align: center;
    justify-content: center;
    background: #F9E0E3;
    opacity: 0.9;
}
#menu-nav{
    margin-left: 50%;
}
#language{  
    color: #FFFFFF;
    text-align: center;
    margin:0px;
    padding:35px;  
    font-family: 'Lexend';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    border-radius: 50%;
    background-image: url(backgraound-image.svg);
    background-color:#F9E0E3 ;
    background-repeat: no-repeat;
    background-position:15px; 
    border:0px ;  
}
#language:hover{
    background-image: url(background-image-hover.svg);
    cursor: pointer;
}
#menu-nav a {
    text-decoration: none;
    margin-right:32px;
    font-family: 'Lexend';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 25px;
    /* identical to box height */
    align-items: center;
    text-align: center;
    color: #E23125;
}
#hambruger-icon {
    display: none;
    position:absolute;
    right: 10%;
}

#menu-hamburger-button {
    display: none;
}
#navigation{
    transition: all ease-in-out 5s;
}
@media (max-width:950px) {
    #menu{
        height: 60px;
    }
    #language{
        position: fixed;   
        top:2px;
        left:2px;
     }
    #hambruger-icon {
      filter: invert(100%) sepia(100%) saturate(100%) hue-rotate(360deg) brightness(100%) contrast(100%);
      display: flex;
      align-items: center;
    }
    #menu-nav{
        margin:0px;
        
    }
  
    #navigation {
      display: none;
      /* row-gap: 15px; */
      background-color: #F9E0E3;
      justify-content: space-evenly;
      flex-direction: column;
      top: 58px;
      transition: 5s ease-in-out;
      border-bottom: 2px solid #E23125;
    }

    #menu-nav a {
        margin-top: 10px;
        font-size: 23px;
        display: flex;
        justify-content: center;
       width: 100%;
        text-align: center;
    }
}
@media (max-width:460px) {
    #menu-nav a {
        /* margin-left: 100px; */
        text-align: left;
        
    }
}
  