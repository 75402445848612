#contact{
    background: #F9E0E3;
    padding: 150px 20px 150px 20px;
}
#contact-content{
    display: flex;
    width: 100%;
     flex-direction: row;
    column-gap: 60px;
    z-index: 2;
    flex-wrap: wrap;
    justify-content: center;
    color:#E23125;
}
#contact-info{
    max-width: 700px;
}
#kontakt{
    font-family: 'Lexend';
    font-style: normal;
    font-weight: 500;
    font-size: 48px;
    margin-top: 0px;
    margin-bottom: 40px;
}
.text-contact{
    font-family: 'Lexend';
    font-style: normal;
    font-weight: 300;
    font-size: 28px;
    line-height: 35px;
}
#map-content{
    margin:50px 0px 0px 0px;
    width: 524px;
    height: 513px;
}
#map{
    height: 100%;
    width: 100%;
}
.map-container{
    max-width: 100%;
    height: 100%;
    border-radius: 20px;
    border:solid 5px #E23125;
}
@media screen and (max-width:1300px) {
    #map-content{
        margin-top: 10px;
    }
    
}
@media screen and (max-width:800px) {
    #contact{
        padding: 75px 50px 75px 50px;
    }
    #kontakt{
        font-size: 40px;
        margin-top: 0px;
        margin-bottom: 20px;
    }
    .text-contact{
        font-size: 18px;
        line-height: 18px;
    }
    #contact-content{
        justify-content: left;
       
    }
}
@media screen and (max-width:600px) {
    #contact{
        padding: 50px 10px 50px 10px;
    }
    #kontakt{
        font-size: 30px;
        margin-top: 0px;
        margin-bottom: 20px;
    }
    .text-contact{
        font-size: 20px;
        line-height: 20px;
    }
}