#pricelist{
    background: #E23125;
    color: white;
    padding:100px 20px 100px 20px;
    
}
#pricelist-content{
    display: flex;
    width: 100%;
     flex-direction: column;
     align-items: center;
    column-gap: 60px;
    z-index: 2;
    flex-wrap: wrap;
    justify-content: center;
}
#cennik{
    padding: 0px;
    font-family: 'Lexend';
    font-style: normal;
    font-weight: 500;
    font-size: 48px;
    line-height: 60px;
}
.pricelist-info{
    font-family: 'Lexend';
    font-style: normal;
    font-weight: 300;
    font-size: 28px;
    line-height: 35px;
    max-width: 1300px;
    
}
#pricelist-pricelist{
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    column-gap: 60px;
    z-index: 2;
    flex-wrap: wrap;
    justify-content: center;
    
}
.pricelist-info-content{
    margin-bottom: 20px;
    padding:40px;
    width: 550px;
    height: 640px; 
    background: #F9E0E3;
    color:#E23125;
    border-radius: 16px;
    font-family: 'Lexend';
    font-style: normal;
    font-weight: 300;
    font-size: 28px;
    
}
.item{
    display: flex;
    justify-content: space-between;
    margin:0px;
    padding: 0px;
}
hr{
    padding: 0px;
    margin:0px
}
.item-item{
    width: 400px;
}

@media screen and (max-width:800px) {
    #pricelist{
        padding:0px 20px 30px 20px;
    }
    #cennik{
        font-size: 40px;
        line-height: 50px;
    }
    .pricelist-info{
        font-size: 25px;
        line-height: 25px;
        
    }
    .pricelist-info-content{
        padding: 20px;
        font-size: 25px;
        height: auto;
    }
    #pricelist-pricelist{
        width:100%
    }
    .item-item{
        width: auto;
    }
    
}
@media screen and (max-width:600px) {
    #cennik{
        font-size: 30px;
        line-height: 40px;
    }
    .pricelist-info{
        font-size: 20px;
        line-height: 20px;
    }
    .pricelist-info-content{
        font-size: 20px;
    }
}