#footer{
    width: 100%;
    padding: 20%;
    height: 130px;
    padding: 0;
    margin: 0;
    text-align: center;
    justify-content: center;
    background:#E23125;
}
#footer-content{
    padding-left:10%;
    padding-top: 20px;
    display: flex;   
    flex-direction: row;
}
#logo{
    display: flex;
    font-family: 'Lexend';
    font-style: normal;
    font-weight: 500;
    font-size: 35px;
    /* line-height: 46px; */
    color: #F3FBEE; 
    text-align: left;
}
#logo p{
    margin: 0px;
}
#logo span{
    font-size:28px;
}
#logo img{
    margin-right:20px;
}